export const environment = {
    envName: 'staging',
    production: true,
    APIURL: 'https://staging.api.betsnap.com',
    CLIENT_ID: 1,
    CLIENT_SECRET: 'ZnVHgA6YWk1TLwWfuL5FVi0IW4VDLtVYke6kUgEE',
    WEB_SOCKET_URL: 'https://staging.nodejs.betsnap.com',
    GOOGLE_RECAPTCHA_PUBLIC: '6Le5wlYUAAAAAGgfxeTVMpf-inBCQESaLfqiU0iT',
    DEBUG: true,
    bugreporter: false,
    GOOGLE_ANALYTICS_TRACKING_ID: 'G-0MV955D1S0',
    S3_NL_URL: 'https://static.s-tech-services.at/bs2api',
    S3_NL_BUCKET_ROOT_DATASHARE: 'datashare/staging',
    S3_NL_BUCKET_ROOT_TRANSLATIONSHARE: 'translations/production'
};
